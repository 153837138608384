import { ServiceId } from '@/constants';
import { GAMTargetingRules } from '@/types/common';
import { ServiceType } from '@/__generated__/globalTypes';

const getGAMVerticalShortName = (serviceId: string): GAMTargetingRules['vertical'] => {
  if (serviceId === ServiceId.CAREGIGSX || serviceId === ServiceType.CARE_GIGS) {
    return 'cg';
  }
  if (serviceId === ServiceId.PETCAREXX || serviceId === ServiceType.PET_CARE) {
    return 'pc';
  }
  if (serviceId === ServiceId.SENIRCARE || serviceId === ServiceType.SENIOR_CARE) {
    return 'sc';
  }
  if (serviceId === ServiceId.TUTORINGX || serviceId === ServiceType.TUTORING) {
    return 'tu';
  }
  if (serviceId === ServiceId.HOUSEKEEP || serviceId === ServiceType.HOUSEKEEPING) {
    return 'hk';
  }
  if (serviceId === ServiceId.CHILDCARE || serviceId === ServiceType.CHILD_CARE) {
    return 'cc';
  }

  return 'none';
};

export { getGAMVerticalShortName };
