import { GetServerSideProps } from 'next/types';
import { getApolloClient } from '@/lib/ApolloClient';
import GET_PROVIDER_DETAILS from '@/API/getProviderDetails';
import { FeatureFlags, GAMTargetingRules, LDTargetAttributes, PageTemplate } from '@/types/common';
import { UI_FEATURE_FLAGS } from '@/constants';
import {
  getProviderDetails,
  getProviderDetailsVariables,
} from '@/__generated__/getProviderDetails';
import getDeviceCharacteristics from '@/utils/getDeviceCharacteristics';
import reqHeaderToEntriesObj from '@/utils/reqHeaderToEntriesObj';
import {
  getCaregiverId,
  getCaregiver,
  getSimilarCaregivers,
  getSEOSegmentationBreadcrumbs,
  getRevieweeMetrics,
  getReviewsByReviewee,
  getProviderPublicId,
} from '@/API/caregiverDetail';
import { getCaregiverId_seoCaregiverIDsBySeoSegmentID_SEOCaregiverIDsBySEOSegmentIDSuccess_caregiverIDs as SEOCaregiverIDsBySEOSegmentIDType } from '@/__generated__/getCaregiverId';
import { getCaregiver as getCaregiverType } from '@/__generated__/getCaregiver';
import { getSEOSegmentationBreadcrumbs as getSEOSegmentationBreadcrumbsType } from '@/__generated__/getSEOSegmentationBreadcrumbs';
import { ReviewsByReviewee as ReviewsByRevieweeType } from '@/__generated__/ReviewsByReviewee';
import { RevieweeMetrics as RevieweeMetricsType } from '@/__generated__/RevieweeMetrics';
import { ProviderPublicIdType } from '@/types/providerSegmentation';
import { ReviewInfoCareType, ReviewInfoEntityType } from '@/__generated__/globalTypes';
import { seoSimilarCaregiverProfiles as getSimilarCaregiversType } from '@/__generated__/getSimilarCaregivers';
import { getGAMVerticalShortName } from '@/utils/GoogleAdsManager/getGAMVerticalShortName';

const isLocal = process.env.NODE_ENV === 'development';

// Existing Caregiver Detail Page Props
export type GetProviderDetailsDataProps = {
  propsType: 'GetProviderDetailsDataProps';
  deviceChar: any;
  ldClientFlags: FeatureFlags;
  pageData: any;
  seoSegmentId: string;
  serviceShortName: string;
  testName: string | null;
  additionalGAMRules: Partial<GAMTargetingRules>;
};

// Unified Caregiver Detail Page Props
export type CaregiverDetailDataProps = {
  propsType: 'CaregiverDetailDataProps';
  deviceChar: {
    [key: string]: string;
  };
  ldClientFlags: FeatureFlags;
  pageData: {
    getCaregiver: getCaregiverType['getCaregiver'];
    getSEOSegmentationBreadcrumbs:
      | getSEOSegmentationBreadcrumbsType['getSEOSegmentationBreadcrumbs']
      | null;
    revieweeMetrics: RevieweeMetricsType['revieweeMetrics'] | null;
    reviewsByReviewee: ReviewsByRevieweeType['reviewsByReviewee'] | null;
    seoSimilarCaregiverProfiles: getSimilarCaregiversType['seoSimilarCaregiverProfiles'] | null;
    serviceProfileId: SEOCaregiverIDsBySEOSegmentIDType['serviceProfileId'];
    publicId: ProviderPublicIdType['publicId'];
  };
  seoSegmentId: string;
  serviceShortName: string;
  testName: string | null;
  userGeoInfo: string;
  additionalGAMRules: Partial<GAMTargetingRules>;
};

export type CaregiverDetailPageProps = GetProviderDetailsDataProps | CaregiverDetailDataProps;

const getCaregiverDetailData: GetServerSideProps<CaregiverDetailPageProps> = async ({
  query,
  req,
  res,
}) => {
  try {
    const startPageGSSP = performance.now();
    const apolloOptions = {
      logError: req.log.error.bind(req.log),
      logInfo: req.log.info.bind(req.log),
    };

    if (typeof query.vertical === 'undefined') {
      return {
        notFound: true,
      };
    }

    const startApolloClient = performance.now();
    const apolloClient = getApolloClient(apolloOptions);
    const stopApolloClient = performance.now();
    const seoSegmentId = `/p/${query.username}/${query.vertical}`;
    const isSeniorCare = Boolean(query.vertical.toString().includes('sc'));
    const isChildCare = Boolean(query.vertical.toString().includes('cc'));
    const isHousekeeping = Boolean(query.vertical.toString().includes('hk'));
    const isTutoring = Boolean(query.vertical.toString().includes('tu'));
    const isPetCare = Boolean(query.vertical.toString().includes('pc'));
    const isCareGigs = Boolean(query.vertical.toString().includes('cg'));
    const isSpecialNeeds = Boolean(query.vertical.toString().includes('sn'));
    const userAgent = req.headers['user-agent'] || '';
    const userGeoInfo = req.headers['x-akamai-edgescape']?.toString() ?? '';
    const deviceCharHeader = req.headers['x-akamai-device-characteristics']?.toString() || '';
    const deviceChar = isLocal
      ? getDeviceCharacteristics(userAgent, deviceCharHeader)
      : reqHeaderToEntriesObj(deviceCharHeader, ';');

    const getServiceShortNameProp = (prop: string | string[]) => {
      if (Array.isArray(prop)) {
        return prop[0];
      }

      return prop;
    };

    const getTestNameProp = (prop: string | string[] | undefined) => {
      if (Array.isArray(prop)) {
        return prop[0];
      }
      if (typeof prop === 'string') {
        return prop;
      }
      return null;
    };

    let pageDesign: 'LEGACY' | 'UNIFIED';
    const startGetPageData = performance.now();
    let pageData;
    let additionalGAMRules: Partial<GAMTargetingRules> = {};

    // Unified Provider Profiles Rollout to all verticals EXCEPT CareGigs & SpecialNeeds profiles.
    const serveUnifiedProfile = !(isCareGigs || isSpecialNeeds);

    if (serveUnifiedProfile) {
      // unified
      pageDesign = 'UNIFIED';
      const getCaregiverIdQuery = await getCaregiverId(apolloClient, {
        seoSegmentId,
      });

      if (
        getCaregiverIdQuery.error ||
        getCaregiverIdQuery.queryResponse.errors ||
        getCaregiverIdQuery.queryResponse.data.seoCaregiverIDsBySeoSegmentID?.__typename ===
          'SEOCaregiverIDsBySEOSegmentIDError' ||
        !getCaregiverIdQuery.queryResponse.data.seoCaregiverIDsBySeoSegmentID?.caregiverIDs
          ?.caregiverUUID
      ) {
        const errors = {
          getCaregiverIdQuery: getCaregiverIdQuery.error,
          getCaregiverIdQueryResponseErrors: getCaregiverIdQuery.queryResponse?.errors,
        };
        req.log.error({
          event: 'SeoProviderDetailPageLoadError',
          seoSegmentId,
          userAgent,
          pageDesign,
          query,
          res,
          deviceChar,
          errors,
          errorCode: 'CAREGIVER_ID_RETREVIAL_FAILED',
          errorMsg: "ERROR - Couldn't get Caregiver ID",
        });
        return {
          notFound: true,
        };
      }

      const {
        queryResponse: {
          data: {
            seoCaregiverIDsBySeoSegmentID: { caregiverIDs },
          },
        },
      } = getCaregiverIdQuery;

      const getProviderPublicIdQuery = await getProviderPublicId(apolloClient, {
        providerId: caregiverIDs.caregiverUUID,
      });

      if (
        getProviderPublicIdQuery.error ||
        getProviderPublicIdQuery.queryResponse.errors ||
        getProviderPublicIdQuery.queryResponse.data.provider?.__typename ===
          'ProviderLookupError' ||
        !getProviderPublicIdQuery.queryResponse.data.provider?.provider?.publicId
      ) {
        const errors = {
          getProviderPublicIdQuery: getProviderPublicIdQuery.error,
          getProviderPublicIdQueryResponseErrors: getProviderPublicIdQuery.queryResponse?.errors,
        };
        req.log.error({
          event: 'SeoProviderDetailPageLoadError',
          seoSegmentId,
          userAgent,
          pageDesign,
          query,
          res,
          deviceChar,
          errors,
          errorCode: 'CAREGIVER_PUBLIC_ID_RETREVIAL_FAILED',
          errorMsg: "ERROR - Couldn't get Caregiver Public ID",
        });
      }
      const provider = getProviderPublicIdQuery?.queryResponse?.data?.provider;

      const publicId =
        provider && provider.__typename === 'ProviderLookupSuccess' && provider.provider?.publicId
          ? provider.provider.publicId
          : null;

      const reviewInfoCareType = () => {
        if (isChildCare) {
          return ReviewInfoCareType.CHILD_CARE;
        }
        if (isPetCare) {
          return ReviewInfoCareType.PET_CARE;
        }
        if (isSeniorCare) {
          return ReviewInfoCareType.SENIOR_CARE;
        }
        if (isTutoring) {
          return ReviewInfoCareType.TUTORING;
        }
        if (isHousekeeping) {
          return ReviewInfoCareType.HOUSEKEEPING;
        }
        return ReviewInfoCareType.CHILD_CARE;
      };

      const careType = reviewInfoCareType();

      const [
        getCaregiverResponse,
        getSEOSegmentationBreadcrumbsResponse,
        getRevieweeMetricsResponse,
        getReviewsByRevieweeResponse,
        similarCaregiversResponse,
      ] = await Promise.all([
        getCaregiver(apolloClient, {
          getCaregiverId: caregiverIDs.caregiverUUID,
          serviceId: caregiverIDs.serviceId,
          isChildCare,
          isPetCare,
          isSeniorCare,
          isTutoring,
          isHousekeeping,
        }),
        getSEOSegmentationBreadcrumbs(apolloClient, {
          seoSegmentId,
        }),
        getRevieweeMetrics(apolloClient, {
          revieweeId: caregiverIDs.caregiverUUID,
          revieweeType: ReviewInfoEntityType.PROVIDER,
          careType,
        }),
        getReviewsByReviewee(apolloClient, {
          revieweeId: caregiverIDs.caregiverUUID,
          revieweeType: ReviewInfoEntityType.PROVIDER,
          careType,
        }),
        getSimilarCaregivers(apolloClient, {
          seoSegmentId,
        }),
      ]);
      if (
        getCaregiverResponse.error ||
        getCaregiverResponse.queryResponse.errors ||
        getCaregiverResponse.queryResponse.data.getCaregiver.profiles === null
      ) {
        const errors = {
          getCaregiverResponseError: getCaregiverResponse.error,
          getCaregiverQueryResponseError: getCaregiverResponse.queryResponse?.errors,
        };

        req.log.error({
          event: 'SeoProviderDetailPageLoadError',
          seoSegmentId,
          userAgent,
          deviceChar,
          pageDesign,
          query,
          res,
          errors,
          errorCode: 'CAREGIVER_PROFILE_RETRIEVAL_FAILED',
          errorMsg: "ERROR - Couldn't get the Caregiver Profile",
        });

        return {
          notFound: true,
        };
      }

      const breadcrumbs = getSEOSegmentationBreadcrumbsResponse.queryResponse
        ? getSEOSegmentationBreadcrumbsResponse.queryResponse.data
        : { getSEOSegmentationBreadcrumbs: null };

      const revieweeMetrics = getRevieweeMetricsResponse.queryResponse
        ? getRevieweeMetricsResponse.queryResponse.data
        : { revieweeMetrics: null };

      const revieweeReviews = getReviewsByRevieweeResponse.queryResponse
        ? getReviewsByRevieweeResponse.queryResponse.data
        : { reviewsByReviewee: null };

      const similarCaregivers = similarCaregiversResponse.queryResponse
        ? similarCaregiversResponse.queryResponse.data
        : { seoSimilarCaregiverProfiles: null };

      additionalGAMRules = {
        vertical: getGAMVerticalShortName(careType),
        contType: 'seeker',
      };

      pageData = {
        ...getCaregiverResponse.queryResponse.data,
        ...breadcrumbs,
        ...revieweeMetrics,
        ...revieweeReviews,
        ...similarCaregivers,
        publicId,
        serviceProfileId: caregiverIDs.serviceProfileId,
      };
    } else {
      // legacy
      pageDesign = 'LEGACY';
      const { data } = await apolloClient.query<getProviderDetails, getProviderDetailsVariables>({
        query: GET_PROVIDER_DETAILS,
        variables: {
          seoSegmentId,
          isSeniorCare,
          isChildCare,
          isHousekeeping,
          isTutoring,
          isPetCare,
          isCareGigs,
          isSpecialNeeds,
          profileDetailsPage: true,
        },
      });

      pageData = data;

      // If we don't have a caregiver return a 404
      if (
        'getSEOSegmentationCaregiverDetails' in pageData &&
        pageData.getSEOSegmentationCaregiverDetails === null
      ) {
        req.log.error({
          event: 'SeoProviderDetailPageLoadError',
          seoSegmentId,
          userAgent,
          pageDesign,
          query,
          res,
          deviceChar,
          errorCode: 'CAREGIVER_DETAILS_RETRIEVAL_FAILED',
          errMsg: 'ERROR - getSEOSegmentationCaregiverDetails is NULL',
        });
        return {
          notFound: true,
        };
      }
    }

    const stopGetPageData = performance.now();
    const startLD = performance.now();
    const ldClientFlags: FeatureFlags = {};
    const { ldClient, ldUser } = req.careContext;
    const ldTargetAttributes: LDTargetAttributes = {
      seoSegmentId,
      deviceChar,
      serviceShortName: query.vertical,
      pageTemplate: PageTemplate.PROVIDER_PROFILE,
    };

    if (ldClient && ldUser) {
      ldUser.custom = {
        ...ldUser.custom,
        seoSegmentId,
        pageTemplate: PageTemplate.PROVIDER_PROFILE,
      };
      const clientFlagPromises = Object.values(UI_FEATURE_FLAGS)
        .filter((flag) => flag.condition(ldTargetAttributes))
        .map(async (flag) => {
          ldClientFlags[flag.id] = await ldClient.variationDetail(flag.id, ldUser, undefined);
        });

      await Promise.all(clientFlagPromises);
    }

    const stopLD = performance.now();
    let pageProps: { props: CaregiverDetailPageProps };
    if ('getSEOSegmentationCaregiverDetails' in pageData) {
      pageProps = {
        props: {
          propsType: 'GetProviderDetailsDataProps',
          serviceShortName: getServiceShortNameProp(query.vertical),
          testName: getTestNameProp(query.testIDToFireTestExposureEvent),
          deviceChar,
          seoSegmentId,
          ldClientFlags,
          pageData,
          additionalGAMRules: {
            // doesn't apply to cg or sn vertical on legacy provider profile page
            vertical: 'none',
            contType: 'provider',
          },
        },
      };
    } else {
      pageProps = {
        props: {
          propsType: 'CaregiverDetailDataProps',
          serviceShortName: getServiceShortNameProp(query.vertical),
          testName: getTestNameProp(query.testIDToFireTestExposureEvent) || null,
          deviceChar,
          seoSegmentId,
          ldClientFlags,
          pageData,
          userGeoInfo,
          additionalGAMRules,
        },
      };
    }

    req.log.info({
      event: 'SeoProviderDetailPageLoadSuccess',
      seoSegmentId,
      userAgent,
      pageDesign,
      deviceChar,
      query,
      res,
    });

    const stopPageGSSP = performance.now();
    req.careContext.serverTimings.pageGSSP = stopPageGSSP - startPageGSSP;
    req.careContext.serverTimings.ldClientFlags = stopLD - startLD;
    req.careContext.serverTimings.apolloClient = stopApolloClient - startApolloClient;
    req.careContext.serverTimings.pageData = stopGetPageData - startGetPageData;

    return pageProps;
  } catch (err) {
    req.log.error({
      event: 'SeoProviderDetailPageLoadError',
      query,
      res,
      err,
      errorCode: 'UNKNOWN_ERROR',
      errorMsg: 'ERROR - An unknown error occured in getServerSideProps',
    });
    return {
      notFound: true,
    };
  }
};

export default getCaregiverDetailData;
